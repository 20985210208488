

@font-face {
  font-family: 'Raleway';
  src: url("./fonts/Raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway-Thin';
  src: url("./fonts/Raleway/Raleway-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway-Light';
  src: url("./fonts/Raleway/Raleway-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway-ExtraLight';
  src: url("./fonts/Raleway/Raleway-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url("./fonts/Raleway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway-Black';
  src: url("./fonts/Raleway/Raleway-Black.ttf") format("truetype");
}

$Raleway-Thin: 'Raleway-Thin', sans-serif;
$Raleway-Light: 'Raleway-Light', sans-serif;
$Raleway-ExtraLight: 'Raleway-ExtraLight', sans-serif;
$Raleway: 'Raleway', sans-serif;
$Raleway-Bold: 'Raleway-Bold', sans-serif;
$Raleway-Black: 'Raleway-Black', sans-serif;

//Roboto font

@font-face {
  font-family: 'Roboto';
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto-Black';
  src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

$Roboto: 'Roboto', sans-serif;
$Roboto-Thin: 'Roboto-Thin', sans-serif;
$Roboto-Black: 'Roboto-Black', sans-serif;
