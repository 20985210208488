@import './config/style/colors.scss';
@import './config/style/fonts.scss';

/* First layer of style */
body {
  margin: 0;
  padding: 0;
  text-align: center;

  background-color: $black;
  color: $white;

  font-family: $Raleway;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  overflow: hidden;
}

.App {
  text-align: center;

  .loading-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}